import { decodePath } from 'ufo'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const siteConfig = useSiteConfig()
  const indexStore = useIndexStore()
  const sagaPaperStore = useSagaPaperStore()

  const isFirstPageLoad = decodePath(to.fullPath) === decodePath(from.fullPath)
  const hasPaperChanged = to.params.paper !== from.params.paper

  if (isFirstPageLoad || hasPaperChanged) {
    indexStore.setCurrentPaper(to.params.paper?.toString())

    if (typeof to.params.paper !== 'string') {
      sagaPaperStore.setActivePaper(siteConfig.frontPagePaperSlug) // dont allow the :paper routes to render for the frontpage slug
      return
    }

    sagaPaperStore.setActivePaper(to.params.paper)
  }
})
