import { parse, parseSetCookie, splitSetCookieString } from 'cookie-es'
import type { NitroFetchRequest } from 'nitropack'
import type { H3Event, RouterMethod } from 'h3'
import type { FetchOptions as _FetchOptions } from 'ofetch'

// Using extended FetchOptions instead of NitroFetchOptions due to a TypeScript bug in Nitro: https://github.com/nitrojs/nitro/issues/2758
interface FetchOptions extends _FetchOptions {
  method?: Uppercase<RouterMethod> | RouterMethod
}

/**
 * Wrapper around $fetch for calling our Nitro endpoints that forwards client headers and handles cookies set during the request.
 *
 * This solves the problem where refreshing your tokens twice in one request would cause the second refresh to use the old access token and fail.
 */
export const useNitroFetch = () => {
  const requestFetch = useRequestFetch()
  const event = useRequestEvent()

  return <
    T,
    R extends NitroFetchRequest = NitroFetchRequest,
    O extends FetchOptions = FetchOptions
  >(
    url: R,
    options: O = {} as O
  ) => {
    if (import.meta.server) {
      options.onRequest = addHook(options.onRequest, ({ options }) => {
        if (!event) return

        injectNewCookies(event, options.headers)
      })

      options.onResponse = addHook(options.onResponse, ({ response }) => {
        if (!event) return

        forwardNewCookies(event, response)
      })
    }

    return requestFetch<unknown>(url, {
      context: import.meta.server ? { cf: event?.context.cf } : undefined,
      credentials: import.meta.client ? 'include' : undefined, // Include cookies in the request on the client
      retry: 0,
      ...options,
    }) as Promise<T>
  }
}

/**
 * Add a default fetch hook to the existing hooks so we don't just overwrite them
 * @param hookOrHooks The fetch hook or hooks sent as options
 * @param hook The fetch hook to add in front of the existing hooks
 */
const addHook = <T>(hookOrHooks: T | T[] | undefined, hook: T) => {
  if (!hookOrHooks) return hook

  return Array.isArray(hookOrHooks)
    ? [hook, ...hookOrHooks]
    : [hook, hookOrHooks]
}

/**
 * Transfer the set-cookie header from the API response to the current SSR request.
 * A Nitro plugin forwards the set-cookie headers to the client at the end of the request.
 */
const forwardNewCookies = (event: H3Event, response: Response) => {
  const cookieString = response.headers.getSetCookie()

  if (!cookieString.length) return

  const cookieStrings = splitSetCookieString(cookieString)

  for (const cookieString of cookieStrings) {
    const cookie = parseSetCookie(cookieString)

    // Update refs
    if (cookie.name === 'AccessToken' && event.context.newAccessToken) {
      event.context.newAccessToken.value = cookie.value || null
    }

    event.context.newCookies ||= {}
    event.context.newCookies[cookie.name] = cookie
  }
}

/**
 * Inject new cookies set during the request in request cookies for subrequests, so we don't send the old access token to the Nitro endpoints
 */
const injectNewCookies = (event: H3Event, headers: Headers) => {
  if (!event.context.newCookies) return

  const newCookies = Object.values(event.context.newCookies)
  if (newCookies.length === 0) return

  // Parse the existing cookies
  const cookies = parse(headers.get('Cookie') || '')

  // Overwrite cookies that have been updated
  for (const cookie of newCookies) {
    cookies[cookie.name] = cookie.value
  }

  // Convert the cookies object back to a string and add it to the request headers
  headers.set(
    'Cookie',
    Object.entries(cookies)
      .map(([name, value]) => `${name}=${value}`)
      .join('; ')
  )
}
