export default defineNuxtRouteMiddleware(async (to) => {
  // Only run on client-side
  if (import.meta.server) return

  const nuxtApp = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()
  const indexStore = useIndexStore()
  const userStore = useUserStore()

  const loginType = userStore.user?.loginType

  let referrer
  if (document?.referrer) referrer = document.referrer

  const data = {
    schemaVersion: 3,
    route: {
      name: to.name,
      path: to.path,
      hash: to.hash,
      query: to.query,
      params: to.params,
      fullPath: to.fullPath,
    },
    login: {
      type: loginType,
    },
    userId: userStore.user?.userId,
    clientId: userStore.user?.clientId,
    context: {
      userGuid: userStore.user?.userGuid,
      referrer,
      sessionId: indexStore.sessionId,
    },
  }

  if (runtimeConfig.public.cosmos?.debug) {
    console.log('Cosmos pageView data:', data)
  }

  if (runtimeConfig.public.cosmos?.enabled) {
    nuxtApp.$api.cosmos.pageView(data)
  }
})
