/**
 * Access consent state from Cookiebot
 * Returns one ref for each consent type instead of a single ref with all consent types to make destructuring easier
 */
export const useCookieConsent = () => {
  /**
   * Whether the user has decided on their cookie preferences.
   * Denying non-necessary cookies is also considered an answer.
   */
  const answered = useState('cookieConsentAnswered', () => shallowRef(false))

  const necessary = useState('cookieConsentNecessary', () => shallowRef(true))
  const functional = useState('cookieConsentFunctional', () =>
    shallowRef(false)
  )
  const statistic = useState('cookieConsentStatistic', () => shallowRef(false))
  const marketing = useState('cookieConsentMarketing', () => shallowRef(false))

  return {
    answered,
    necessary,
    functional,
    statistic,
    marketing,
  }
}
