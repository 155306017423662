import {
  faCheck as proFasFaCheck,
  faPhone as proFasFaPhone,
  faVolume as proFasFaVolume,
  faVolumeSlash as proFasFaVolumeSlash,
  faLocationDot as proFasFaLocationDot,
  faPlus as proFasFaPlus,
  faMinus as proFasFaMinus,
  faBarsFilter as proFasFaBarsFilter,
  faKey as proFasFaKey,
  faUser as proFasFaUser,
  faBookmark as proFasFaBookmark,
  faArrowsToDot as proFasFaArrowsToDot,
  faEnvelope as proFasFaEnvelope,
  faHeart as proFasFaHeart,
  faCreditCard as proFasFaCreditCard,
  faCircleInfo as proFasFaCircleInfo,
  faEye as proFasFaEye,
  faEyeSlash as proFasFaEyeSlash
} from '@fortawesome/pro-solid-svg-icons'

import {
  faXmarkLarge as proFarFaXmarkLarge,
  faCalendar as proFarFaCalendar,
  faMapMarkerAlt as proFarFaMapMarkerAlt,
  faCircleInfo as proFarFaCircleInfo,
  faClose as proFarFaClose,
  faPhone as proFarFaPhone,
  faEnvelope as proFarFaEnvelope,
  faInfoCircle as proFarFaInfoCircle,
  faCube as proFarFaCube,
  faArrowRight as proFarFaArrowRight,
  faArrowLeft as proFarFaArrowLeft,
  faFilePdf as proFarFaFilePdf,
  faChevronRight as proFarFaChevronRight,
  faChevronDown as proFarFaChevronDown,
  faChevronUp as proFarFaChevronUp,
  faChevronLeft as proFarFaChevronLeft,
  faCopy as proFarFaCopy,
  faTrash as proFarFaTrash
} from '@fortawesome/pro-regular-svg-icons'

import {
  faCircleCheck as proFalFaCircleCheck,
  faMinus as proFalFaMinus,
  faPlus as proFalFaPlus,
  faBan as proFalFaBan,
  faUser as proFalFaUser,
  faPlay as proFalFaPlay,
  faBullseyeArrow as proFalFaBullseyeArrow,
  faCalendar as proFalFaCalendar,
  faDesktop as proFalFaDesktop,
  faFileAlt as proFalFaFileAlt,
  faAd as proFalFaAd,
  faTriangleExclamation as proFalFaTriangleExclamation,
  faArrowsRotate as proFalFaArrowsRotate
} from '@fortawesome/pro-light-svg-icons'

import {
  faEye as proFatFaEye,
  faBullseyeArrow as proFatFaBullseyeArrow,
  faMobile as proFatFaMobile,
  faNewspaper as proFatFaNewspaper,
  faChartNetwork as proFatFaChartNetwork,
  faPodiumStar as proFatFaPodiumStar,
  faHandshakeAlt as proFatFaHandshakeAlt
} from '@fortawesome/pro-thin-svg-icons'

export default {proFasFaCheck, proFasFaPhone, proFasFaVolume, proFasFaVolumeSlash, proFasFaLocationDot, proFasFaPlus, proFasFaMinus, proFasFaBarsFilter, proFasFaKey, proFasFaUser, proFasFaBookmark, proFasFaArrowsToDot, proFasFaEnvelope, proFasFaHeart, proFasFaCreditCard, proFasFaCircleInfo, proFasFaEye, proFasFaEyeSlash, proFarFaXmarkLarge, proFarFaCalendar, proFarFaMapMarkerAlt, proFarFaCircleInfo, proFarFaClose, proFarFaPhone, proFarFaEnvelope, proFarFaInfoCircle, proFarFaCube, proFarFaArrowRight, proFarFaArrowLeft, proFarFaFilePdf, proFarFaChevronRight, proFarFaChevronDown, proFarFaChevronUp, proFarFaChevronLeft, proFarFaCopy, proFarFaTrash, proFalFaCircleCheck, proFalFaMinus, proFalFaPlus, proFalFaBan, proFalFaUser, proFalFaPlay, proFalFaBullseyeArrow, proFalFaCalendar, proFalFaDesktop, proFalFaFileAlt, proFalFaAd, proFalFaTriangleExclamation, proFalFaArrowsRotate, proFatFaEye, proFatFaBullseyeArrow, proFatFaMobile, proFatFaNewspaper, proFatFaChartNetwork, proFatFaPodiumStar, proFatFaHandshakeAlt}