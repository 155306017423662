import type { MinimalUser } from '~/api/account'

import type { LoginType } from '~/typesManual/jwt'
import type { ArticleUserDto } from '~/typesAuto/apicore/v1'

export type LoginResponse = {
  status: 'success' | 'error'
  message?: string
  code?: number
  error?: unknown
}

export type NuxtJwtLoginResponse = {
  status: 'success' | 'error'
  accessToken?: string
}

export type TempUser = {
  // statemanagement
  loadedAccessToken?: string

  // basic
  userId: number
  userGuid: string
  loginType: LoginType
  name?: string
  email: string
  workEmailConfirmed: boolean
  hasActiveSubscription: boolean
  papers: number[]
  isAdmin: boolean
  isPasswordSet?: boolean

  // organistation
  clientId?: number
  jobPlace?: string

  // mit altinget
  educationId?: number | null
  userBranchId?: number | null
  userTitleId?: number | null
  countryId?: number | null
  birthyear?: string
  zipcode?: string
  contactPhone: string

  // extra
  avatar?: string
  readingList: ArticleUserDto[]

  // deprecated
  legacyAutoToken: string
  contactEmail?: string
}

export const useUserStore = defineStore('user', () => {
  const nuxtApp = useNuxtApp()
  const config = useRuntimeConfig()
  const siteConfig = useSiteConfig()
  const nitroFetch = useNitroFetch()

  const disableIpLoginCookie = useCookie<boolean>('disableIPlogin')

  const accessToken = useAccessToken()

  /*
  // State
  */
  const user = ref<TempUser | null>(null)

  /*
  // Getters
  */
  const loadedAccessToken = computed(() => user.value?.loadedAccessToken)

  const isLoggedIn = computed(() => Boolean(user.value))

  const hasLoginType = (...userTypes: LoginType[]) => {
    if (!user.value?.loginType) {
      return false
    }

    return userTypes.includes(user.value.loginType)
  }

  const hasSubscription = (paperId: number) => {
    if (!user.value) {
      return false
    }

    return user.value.papers.includes(paperId)
  }

  const hasClient = computed(() => user.value?.clientId ?? 0 > 0)

  const hasName = computed(() => !!user.value?.name)

  const hasImage = computed(() => {
    return Boolean(
      user.value?.avatar && user.value.avatar !== 'avatar_grey.svg'
    )
  })

  const hasConfirmedWorkEmail = computed(() => !!user.value?.workEmailConfirmed)

  const emailBelongsToAlrow = computed(() => {
    return /@(mm\.dk|(altinget\.(dk|se|no)))$/g.test(user.value?.email ?? '')
  })

  const id = computed(() => user.value?.userId)

  const missingPassword = computed(() => {
    if (hasLoginType('UsernamePassword')) {
      // Only true if user is Identity and doesn't have a password, since old users don't have the isPasswordSet property
      return Boolean(!user.value?.isPasswordSet)
    }
  })

  /*
  // Actions
  */
  const loadUserFromAccessToken = async () => {
    if (import.meta.client) {
      // Workaround for Firefox and Safari bug where the refreshCookie's BroadcastChannel message isn't delivered in time
      // TODO: Make a minimal reproduction of the bug (Linear issue FE-1491) to get this fixed upstream
      await new Promise((resolve) => setTimeout(resolve, 10))
    }

    if (
      !accessToken.token.value ||
      (user.value && user.value.loadedAccessToken === accessToken.token.value)
    ) {
      return
    }

    const userPayload = accessToken.payload.value
    if (!userPayload) {
      return
    }

    if (
      (userPayload.loginType === 'Sso' ||
        userPayload.loginType === 'UsernamePassword') &&
      !userPayload.userId
    ) {
      console.error('payload:', userPayload)
      console.error('payload userId not found login failed')
      return
    }

    if (userPayload.loginType === 'MagicLink') {
      userPayload.loginType = 'UsernamePassword' // currently all user flows using MagicLink comes from users that have loginType UsernamePassword
    }

    let userData: MinimalUser | undefined = undefined
    let readingList: ArticleUserDto[] = []
    if (
      userPayload.loginType === 'Sso' ||
      userPayload.loginType === 'UsernamePassword'
    ) {
      userData = await nuxtApp.$api.account.getUser(userPayload.userId)
      readingList = await nuxtApp.$api.user.getReadingList(userPayload.userId)
    } else {
      userData = {
        name: userPayload.name,
        workEmail: '',
        autoLogin: '',
        client: undefined,
      }
    }

    user.value = {
      // statemanagement
      loadedAccessToken: accessToken.token.value,
      // basic
      userId: userPayload.userId,
      userGuid: userPayload.userGuid,
      loginType: userPayload.loginType,
      name: userData.name ?? undefined,
      email: userPayload['name'],
      workEmailConfirmed: userPayload.isEmailConfirmed,
      hasActiveSubscription: userPayload.hasActiveSubscription,
      papers: userPayload.paperIds ?? [],
      isAdmin: userPayload.isAdmin,
      isPasswordSet: userPayload.isPasswordSet,

      // organistation
      clientId: userData.client?.id,
      jobPlace: userData.client?.jobPlace,

      // mit altinget
      educationId: userData.educationId,
      userBranchId: userData.userBranchId,
      userTitleId: userData.userTitleId,
      countryId: userData.countryId,

      birthyear: userData.birthyear?.toString() ?? '',
      zipcode: userData.zipcode ?? '',
      contactPhone: userData.contactPhone ?? '',

      // extra
      avatar: userData.avatar ?? undefined,
      readingList: readingList,

      // deprecated
      legacyAutoToken: userData.autoLogin, // used by legacy pages decisionChain iframe.
    } as TempUser

    return user.value
  }

  const refreshUser = async () => {
    if (!accessToken.token.value) return

    const refreshResponse = await accessToken.refresh()
    if (refreshResponse.result === 'Refresh failed') {
      await logout()
      return
    }

    await loadUserFromAccessToken()
  }

  const loginPasswordEdit = async (
    userGuid: string,
    newPassword: string,
    passKey: string
  ) => {
    try {
      const response = await nitroFetch<LoginResponse>(
        '/api/auth/sign-in-password-edit',
        {
          method: 'POST',
          body: {
            userGuid,
            newPassword,
            passKey,
          },
        }
      )
      refreshCookie('AccessToken')

      if (response.status === 'success') {
        await loadUserFromAccessToken()
      }

      return response
    } catch (error) {
      const typedError = error as { response?: { status?: number } }

      const response: LoginResponse = {
        status: 'error',
        code: typedError.response?.status,
        error: typedError,
      }

      return response
    }
  }

  const loginUserJwt = async (username: string, password: string) => {
    try {
      const signinResponse = await nitroFetch<NuxtJwtLoginResponse>(
        '/api/auth/sign-in',
        {
          method: 'POST',
          body: {
            username,
            password,
          },
        }
      )
      refreshCookie('AccessToken')

      if (signinResponse.status === 'success') {
        await loadUserFromAccessToken()
      }

      const response: LoginResponse = {
        status: 'success',
      }
      return response
    } catch (error) {
      const typedError = error as { response?: { status?: number } }

      const response: LoginResponse = {
        status: 'error',
        code: typedError.response?.status,
        error: typedError,
      }

      return response
    }
  }

  const loginUser = async (username: string, password: string) => {
    return loginUserJwt(username, password)
  }

  const loginIp = async () => {
    try {
      const signinResponse = await nitroFetch<LoginResponse>(
        '/api/auth/sign-in-ip',
        { method: 'POST' }
      )
      refreshCookie('AccessToken')

      if (signinResponse.status === 'success') {
        await loadUserFromAccessToken()
      }

      return signinResponse
    } catch (error) {
      console.error('LoginIp failed with error:', error)
      return { status: 'error' }
    }
  }

  /**
   * - magic (remember to encodeURIComponent the token if not already)
   */
  const loginMagic = async (userGuid: string, ott: string) => {
    try {
      const response = await nitroFetch<NuxtJwtLoginResponse>(
        '/api/auth/sign-in-ott',
        {
          method: 'POST',
          body: {
            userGuid,
            ott,
          },
        }
      )

      refreshCookie('AccessToken')

      if (response.status === 'success') {
        await loadUserFromAccessToken()
      }

      return response
    } catch (error) {
      const typedError = error as { response?: { status?: number } }

      const response: LoginResponse = {
        status: 'error',
        code: typedError.response?.status,
        error: typedError,
      }

      return response
    }
  }

  const setFirstTimePassword = async (newPassword: string) => {
    try {
      const response = await $fetch<LoginResponse>(
        '/api/auth/sign-in-password-edit-iteras',
        {
          method: 'POST',
          body: {
            callingService: config.public.site.identityCallingService,
            newPassword,
          },
        }
      )
      refreshCookie('AccessToken')

      if (response.status === 'success') {
        await loadUserFromAccessToken()
      }

      return response
    } catch (error) {
      const typedError = error as { response?: { status?: number } }

      const response: LoginResponse = {
        status: 'error',
        code: typedError.response?.status,
        error: typedError,
      }

      return response
    }
  }

  const logout = async () => {
    const loginType = user.value?.loginType

    await accessToken.signOut()

    user.value = null
    disableIpLoginCookie.value = true

    if (loginType === 'Sso') {
      await navigateTo(`https://${siteConfig.apicoreurl}/ssoindex/signout`, {
        external: true,
      })
    }
  }

  watch(accessToken.token, () => {
    if (!accessToken.token.value) {
      user.value = null
    }
  })

  return {
    user,
    loadedAccessToken,
    isLoggedIn,
    hasSubscription,
    hasLoginType,
    hasClient,
    hasName,
    hasImage,
    hasConfirmedWorkEmail,
    emailBelongsToAlrow,
    id,
    missingPassword,
    loadUserFromAccessToken,
    refreshUser,
    loginUserJwt,
    loginUser,
    loginPasswordEdit,
    loginIp,
    loginMagic,
    logout,
    setFirstTimePassword,
  }
})
