import type { PaperSagaDto } from '~/typesAuto/saga/v2'
import type { NormalizedPaperSagaDto } from '~/typesManual/normalized'
import type { DKPaperSlugs, MmPaperSlugs } from '../typesManual/config/site'

/*
 * TYPES FIX BASED ON THE INVARIANTS WE HAVE. WE CAN REMOVE THIS WHEN THE AUTOTYPES REFLECT THE DATA SEND
 * WILL THROW A 404 IF THE INVARIANTS ARE NOT MET AS A GUARD
 */
const mapToNormalizedPaperSagaDto = (
  rawSagaPaper: PaperSagaDto
): NormalizedPaperSagaDto => {
  let paperId = rawSagaPaper.id
  let paperName = rawSagaPaper.name
  let paperSlug = rawSagaPaper.slug
  let paperDescription = rawSagaPaper.description

  if (paperId === undefined) {
    paperId = -1
    console.error('Paper id is missing')
  }

  if (!paperName) {
    paperName = 'missing'
    console.error('Paper name is missing')
  }

  if (!paperSlug) {
    paperSlug = 'missing'
    console.error('Paper slug is missing')
  }

  if (!paperDescription) {
    paperDescription = 'missing'
    console.error('Paper description is missing')
  }

  return {
    ...rawSagaPaper,
    id: paperId,
    name: paperName,
    slug: paperSlug,
    description: paperDescription,
  }
}

export const useSagaPaperStore = defineStore('sagaPaper', () => {
  const siteConfig = useSiteConfig()

  const paperList = ref<PaperSagaDto[]>([])
  const stateActivePaper = ref<NormalizedPaperSagaDto | undefined>(undefined)

  const activePaper = computed(() => {
    return stateActivePaper.value
      ? stateActivePaper.value
      : mapToNormalizedPaperSagaDto(
          paperList.value.find(
            (x) => x.slug === siteConfig.frontPagePaperSlug
          ) as PaperSagaDto // INVARIANT: frontpage paper is always present
        )
  })

  const isFrontPagePaper = computed(() => {
    return activePaper.value.slug === siteConfig.frontPagePaperSlug
  })

  const setActivePaper = (slug: string) => {
    if (paperList.value.length > 0) {
      if (slug) {
        const foundPaper = paperList.value.find((x) => x.slug === slug)

        if (!foundPaper) {
          throw createError({ statusCode: 404 })
        }

        stateActivePaper.value = mapToNormalizedPaperSagaDto(foundPaper)
        return
      } else {
        throw createError({ statusCode: 404 })
      }
    } else {
      throw createError({ statusCode: 404 })
    }
  }

  // Filter out the frontpage paper
  const nichePapers = computed(() => {
    return paperList.value
      .filter((paper) => paper.slug !== siteConfig.frontPagePaperSlug)
      .map(mapToNormalizedPaperSagaDto)
  })

  // Helper function to get the the correct type from backend
  const getNormalizedPaperList = computed(() => {
    return paperList.value.map(mapToNormalizedPaperSagaDto)
  })

  const isDKPaperSlug = (...slugs: DKPaperSlugs[]) => {
    if (!isSite('altinget_dk')) {
      return false
    }

    return (slugs as string[]).includes(activePaper.value.slug)
  }

  const isMMPaperSlug = (...slugs: MmPaperSlugs[]) => {
    if (!isSite('mm_dk')) {
      return false
    }

    return (slugs as string[]).includes(activePaper.value.slug)
  }

  return {
    // Data list
    paperList,

    // Computed
    activePaper,
    isFrontPagePaper,
    nichePapers,

    // Functions
    setActivePaper,
    isDKPaperSlug,
    isMMPaperSlug,

    getNormalizedPaperList,
  }
})
