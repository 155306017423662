import { LocationQuery } from 'vue-router'

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    let userGuid =
      typeof to.query.userguid === 'string' ? to.query.userguid : ''
    if (!userGuid) {
      // fallback for old query param until backend is updated
      userGuid = typeof to.query.guid === 'string' ? to.query.guid : ''
    }

    if (!userGuid) return

    const userStore = useUserStore()

    const magic = typeof to.query.magic === 'string' ? to.query.magic : ''
    // Login new user with magic
    if (magic) {
      await userStore.loginMagic(userGuid, encodeURIComponent(magic))

      const newQuery: LocationQuery = { ...to.query }
      delete newQuery['magic']

      return navigateTo({
        query: newQuery,
      })
    }

    // Refresh token for existing users that haven't already been refreshed in this request
    if (userStore.isLoggedIn) {
      await userStore.refreshUser()
    }
  }
})
