import { decodeJwt } from 'jose'
import type { FetchError } from 'ofetch'
import type { JwtPayload } from '~/typesManual/jwt'

let refreshPromise: Promise<
  | {
      accessToken: string
      result: string
    }
  | {
      result: string
      data: unknown
    }
> | null = null

export function useAccessToken() {
  const siteConfig = useSiteConfig()

  const nitroFetch = useNitroFetch()

  const newAccessToken = useNewAccessToken()

  const tokenCookie = useCookie('AccessToken', {
    readonly: true,
    secure: true,
    sameSite: 'none',
    path: '/',
  })

  const unvalidatedToken = computed(() => {
    if (import.meta.server && newAccessToken.value !== undefined) {
      return newAccessToken.value || undefined
    }

    return tokenCookie.value
  })

  const payload = computed(() => {
    if (!unvalidatedToken.value) return

    const payload = decodeJwt<JwtPayload>(unvalidatedToken.value)

    // Ignore tokens for other sites (ex. Hotshot)
    if (payload?.aud !== siteConfig.accessTokenAudience) {
      console.log(
        `Mismatched audience cannot be used: ${payload?.aud}, ${siteConfig.accessTokenAudience}`
      ) // mainly for developement debugging
      return
    }

    return payload
  })

  const token = computed(() => {
    if (!payload.value) return

    return unvalidatedToken.value
  })

  const isValid = () => {
    if (!payload.value) return false

    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000)

    // For debugging: +895 (refresh after 5s. 14m55s before expiration). For prod: +30 (refresh 30s before expiration)
    // return secondsSinceEpoch + 895 < payload.value.exp
    return secondsSinceEpoch + 30 < payload.value.exp
  }

  const refresh = async () => {
    // Inspired by https://nuxt.com/docs/getting-started/data-fetching#passing-headers-and-cookies
    const response = await nitroFetch<{ accessToken: string }>(
      '/api/auth/refresh-token',
      {
        method: 'POST',
      }
    ).catch(({ data }: FetchError) => ({ result: 'Refresh failed', data }))
    refreshCookie('AccessToken')

    return {
      result: 'Refresh success',
      ...response,
    }
  }

  // Dedupe the refresh function so there's only one refresh request at a time. All other requests will await the same promise.
  const dedupedRefresh = () => {
    if (!refreshPromise) {
      refreshPromise = refresh().finally(() => {
        refreshPromise = null
      })
    }

    return refreshPromise
  }

  const signOut = async () => {
    const response = await nitroFetch('/api/auth/sign-out')
    refreshCookie('AccessToken')
    return response
  }

  return {
    payload,
    token,
    unvalidatedToken,
    isValid,
    refresh: dedupedRefresh,
    signOut,
  }
}

/**
 * On the server the cookie is only read at the start of the request, so we need a way to keep track of updates during the current SSR response.
 */
export function useNewAccessToken() {
  const event = useRequestEvent()
  if (import.meta.client || !event) {
    return ref<string | null | undefined>()
  }

  event.context.newAccessToken ||= ref()

  return event.context.newAccessToken
}

/**
 * On the server the cookie is only read at the start of the request, so we need a way to keep track of updates during the current SSR response.
 */
export function useNewRefreshToken() {
  const event = useRequestEvent()
  if (import.meta.client || !event) {
    return ref<string | null | undefined>()
  }

  event.context.newRefreshToken ||= ref()

  return event.context.newRefreshToken
}
