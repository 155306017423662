import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const suntNative = useScript({"key":"suntNative","src":"https://d.suntcontent.se/cds/delivery/init","async":true,"tagPosition":"head","fetchpriority":"high","type":"text/javascript"}, { use: () => ({ suntNative: window.suntNative }) })
    return { provide: { $scripts: { suntNative } } }
  }
})