export default defineNuxtPlugin({
  name: 'intlFormatter',
  setup: () => {
    const siteConfig = useSiteConfig()

    const localeCode = siteConfig.localeCode
    const currency = siteConfig.currency

    const numberFormatter = new Intl.NumberFormat(localeCode)

    const currencyFormatterNoDecimals = new Intl.NumberFormat(localeCode, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    })
    const currencyFormatterTwoDecimals = new Intl.NumberFormat(localeCode, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    })

    const listAndFormatter = new Intl.ListFormat(localeCode, {
      type: 'conjunction',
    })
    const listOrFormatter = new Intl.ListFormat(localeCode, {
      type: 'disjunction',
    })

    return {
      provide: {
        formatNumber: (number: number | bigint | Intl.StringNumericLiteral) =>
          numberFormatter.format(number),
        formatCurrency: (number: number) =>
          Number.isInteger(number)
            ? currencyFormatterNoDecimals.format(number)
            : currencyFormatterTwoDecimals.format(number),

        formatDate: (date: number | string | Date) =>
          new Date(date).toLocaleDateString(localeCode, {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
          }),
        formatList: (list: Iterable<string>, type = 'conjunction') =>
          type === 'conjunction'
            ? listAndFormatter.format(list)
            : listOrFormatter.format(list),
      },
    }
  },
})
