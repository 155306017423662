<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AlrowTools />
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const dataLayer = useDataLayer()
const config = useRuntimeConfig()

nuxtApp.hook('page:start', () => {
  dataLayer.basic.resetDatabay()

  const googleTagManager = window.google_tag_manager
  if (!googleTagManager) {
    return
  }

  const gtm = window.google_tag_manager[config.public.gtm.id]
  if (gtm) {
    gtm.dataLayer.reset()
  }
})

nuxtApp.hook('page:finish', () => {
  const userCodeData = dataLayer.basic.getUserCoreTrackingData()
  const routeCoreData = dataLayer.basic.getRouteCoreTrackingData()

  dataLayer.addToTracking(userCodeData)
  dataLayer.addToTracking(routeCoreData)
  dataLayer.basic.trackPageView()
})

// Stop NuxtLinks missing required params from crashing the page
onErrorCaptured((err, instance) => {
  if (err.message.startsWith('Missing required param')) {
    const linkTo = instance && 'to' in instance ? instance?.to : undefined

    if (import.meta.client) {
      nuxtApp.$sentry.captureException(err, { extra: { linkTo } })
    }

    return false
  }
})
</script>
