import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
  name: 'sentry',
  // dependsOn: ['auth'],  Not needed since this is a client plugin and with run after the auth server plugin
  setup: (nuxtApp) => {
    const environment = process.env.NODE_ENV
    const config = useRuntimeConfig()
    const sentryConfig = config.public.sentry
    const userStore = useUserStore()
    const vueApp = nuxtApp.vueApp
    const router = useRouter()

    if (!sentryConfig) return
    if (config.public.disableSentry) return

    // Don't send errors from these third-party scripts to Sentry.
    // Read more on https://docs.sentry.io/platforms/javascript/configuration/filtering/
    const denyUrls = [
      'https://macro.adnami.io/macro/',
      'https://static.cloudflareinsights.com/beacon.min.js/',
    ]

    Sentry.init({
      dsn: sentryConfig.dsn,
      app: [vueApp],
      environment,
      integrations: [Sentry.browserTracingIntegration({ router })],
      sampleRate: 0.1,
      tracesSampleRate: 0.05,
      denyUrls,
      beforeSend(event) {
        console.error('Sentry error', event)
        return event
      },
    })
    if (userStore.isLoggedIn && userStore.user) {
      const user = userStore.user
      Sentry.setUser({
        id: user?.userId?.toString(),
        email: user?.email,
      })
    }
    Sentry.attachErrorHandler(vueApp, {
      logErrors: false,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })

    return {
      provide: {
        sentry: Sentry,
      },
    }
  },
})
