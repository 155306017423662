// This plugin depends on user store, saga paper store and router

export default defineNuxtPlugin({
  name: 'gpt',
  setup: async () => {
    const userStore = useUserStore()
    const sagaPaperStore = useSagaPaperStore()
    const router = useRouter()
    const route = useRoute()

    // This function is needed only for testing. It loads the GPT script and waits for it to be ready.
    // await loadGptScript()
    // await waitForGptReady()

    window.googletag = window.googletag || { cmd: [] }

    const updateTargeting = () => {
      window.googletag.cmd.push(() => {
        const paper = sagaPaperStore.isFrontPagePaper
          ? route.path
          : sagaPaperStore.activePaper.slug

        if (!window.googletag.pubads) {
          console.warn('[GPT] pubads is not ready')
          return
        }
        const pubads = window.googletag.pubads()

        pubads.setTargeting('isLoggedIn', String(userStore.isLoggedIn))
        pubads.setTargeting(
          'activesubscription',
          String(userStore.user?.hasActiveSubscription ?? false)
        )

        pubads.setTargeting('path1', paper)
      })
    }

    // Initial targeting
    updateTargeting()

    // Enable GPT services once — AFTER initial targeting
    window.googletag.cmd.push(() => {
      window.googletag.enableServices()
    })

    // Watch login state
    watch(
      () => [userStore.isLoggedIn, userStore.user?.hasActiveSubscription],
      () => {
        updateTargeting()
      }
    )
    router.afterEach(() => {
      updateTargeting()
    })
  },
})

// This function is needed only for testing. It loads the GPT script and waits for it to be ready.
// function loadGptScript(): Promise<void> {
//   return new Promise((resolve, reject) => {
//     if (window.googletag?.apiReady) return resolve()

//     const script = document.createElement('script')
//     script.async = true
//     script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'

//     script.onload = () => {
//       resolve()
//     }

//     script.onerror = () => {
//       reject(new Error('Failed to load GPT script'))
//     }

//     document.head.appendChild(script)
//   })
// }

// function waitForGptReady(): Promise<void> {
//   return new Promise((resolve) => {
//     const check = () => {
//       if (
//         window.googletag?.apiReady &&
//         typeof window.googletag.pubads === 'function'
//       ) {
//         resolve()
//       } else {
//         setTimeout(check, 50)
//       }
//     }
//     check()
//   })
// }
