import type { CookieBot, CookieBotPayload } from '~/typesManual/cookieBot'

declare const window: {
  Cookiebot?: CookieBot
} & Window

/**
 * Load Cookiebot consent state from cookie or when user accepts cookies.
 * Based on https://github.com/weareheavy/nuxt-cookie-consent
 */
export default defineNuxtPlugin({
  name: 'cookieConsent',
  setup: () => {
    const cookie = useCookie<CookieBotPayload | null>('CookieConsent', {
      decode: decodeCookieBotPayload,
    })

    const cookieConsent = useCookieConsent()

    if (cookie.value) {
      cookieConsent.answered.value = true
      cookieConsent.necessary.value = cookie.value.necessary || false
      cookieConsent.functional.value = cookie.value.preferences || false
      cookieConsent.statistic.value = cookie.value.statistics || false
      cookieConsent.marketing.value = cookie.value.marketing || false
    }

    if (import.meta.client) {
      window.addEventListener('CookiebotOnAccept', () => {
        const consent = window.Cookiebot?.consent
        if (!consent) return

        cookieConsent.answered.value = true
        cookieConsent.necessary.value = consent.necessary || false
        cookieConsent.functional.value = consent.preferences || false
        cookieConsent.statistic.value = consent.statistics || false
        cookieConsent.marketing.value = consent.marketing || false
      })
    }
  },
})

const decodeCookieBotPayload = (value: string) => {
  try {
    return JSON.parse(
      decodeURIComponent(value || '{}')
        .replace(/'/g, '"')
        .replace(/([{[,])\s*([a-zA-Z0-9_]+?):/g, '$1"$2":')
    ) as CookieBotPayload
  } catch {
    return null
  }
}
